import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {setCurrency} from '../../redux/slices/userSlice'

import axios from 'axios'

export default function Convert({usdPrice, oldPrice}) {
    const currency = useSelector(state => state.user.currency)

    const [uah, setUah] = useState(0);
    const [rate, setRate] = useState(42);
    const [old, setOld] = useState(0);

    const getRates = async() => {
        try{
            const res = await axios.get('https://api.monobank.ua/bank/currency')
  
             const euro = res.data.filter(obj => (obj.currencyCodeA === 978 && obj.currencyCodeB === 980));
          
             const dollar = res.data.filter(obj => (obj.currencyCodeA === 840 && obj.currencyCodeB === 980));
             
            

    // const {data} = await axios('https://api.sicvolo.org/wp-json/wp/v2/posts/?include[]=1989');
    setUah(dollar[0].rateSell)
 

    if(currency === "USD"){setRate(dollar[0].rateSell)}
    if(currency === "EUR"){setRate(euro[0].rateSell)}
    if(currency === "UAH"){setRate(1)}
        }catch(e){
            console.log(e);
        }
    }

    useEffect(()=>{
        getRates() 
    }, [currency ])


    useEffect(()=>{
        if(oldPrice){ 
            setOld((parseFloat(oldPrice) * parseFloat(uah) / rate).toFixed(2))
        } 
    }, [uah, rate, currency])

  return (
    <>
        {/* <span className="convertPrice">({(parseFloat(usdPrice) * parseFloat(uah) / rate).toFixed(2)} {currency})</span> */}

       
           {(parseFloat(usdPrice) * parseFloat(uah) / rate).toFixed(2)} {currency} {oldPrice && <span className="oldPrice">{old} {currency}</span>}
    </>
  )
  }